import { createBrowserRouter, Navigate } from "react-router-dom";
import Home from "../pages/home";
import Female from "../pages/female";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to="/ma" replace />,
  },
  {
    path: "/ma",
    element: <Home />,
  },
  {
    path: "/fem",
    element: <Female />,
  },
]);