import styled from "styled-components";

export const FrameBorderView = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 170px;
  max-width: 812px;
  justify-content: center;
  align-items: center;
  z-index: 0;
  .slick-slider .slick-track, .slick-slider .slick-list {
    height: 354px !important;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background: black;
  color: white;
  align-items: center;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  height: calc(100vh);
  overflow-x: hidden;
  overflow-y: auto;
  @media (max-width: 480px) {
    //padding-bottom: 10px;
    width: calc(100vw);
  }
  @media (max-width:960px){
    width: calc(100vw);
    height: auto;
  }
`

export const FrameBody = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 812px;
  width: fit-content;
  border: 1px solid #FAC0540F;
  padding: 30px 60px 0;
  border-radius: 60px;
  gap: 10px;
  align-items: center;
  overflow: hidden;
  height: 100%;
  @media (max-width: 480px) {
    //padding: 0;
    //margin-top: 0;
  }
  @media (max-width: 960px) {
    min-height: calc(100vh);
    padding: 30px 16px 0;
  }
`

export const TxtTitle = styled.div`
  font-family: Ibrand,sans-serif;
  font-size: 64px;
  font-weight: 400;
  line-height: 80px;
  letter-spacing: 0.05em;
  text-align: center;
  text-decoration-skip-ink: none;
`

export const Border1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  //flex: 1;
  border: 2px solid #FAC0540F;
  padding: 20px;
  gap: 10px;
  border-radius: 52px;
  width: fit-content;
  margin-top: -25px;
  @media (max-width: 480px) {
    //padding: 0;
  }
`

export const FrameTpe = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: fit-content;
  padding: 7.71px 15.43px 7.71px 10.29px;
  gap: 5.14px;
  border-radius: 128.57px;
  margin-top: 6px;
  @media (max-width: 480px) {
    //margin-bottom: 0;
  }
`

export const TxtPro = styled.div`
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 15.43px;
  //font-weight: 700;
  line-height: 20.57px;
  text-align: center;
  text-decoration-skip-ink: none;
`

export const Border2 = styled.div`
  display: flex;
  //flex: 1;
  border: 2px solid #FA995417;
  padding: 38px;
  justify-content: center;
  gap: 10px;
  border-radius: 44px;
  width: fit-content;
  position: relative;
  @media (max-width: 480px) {
    //padding: 10px 0 20px;
    border-left: unset;
    border-right: unset;
    border-radius: 0;
  }
`

export const Border3 = styled.div`
  display: flex;
  flex-direction: column;
  //flex: 1;
  border: 2px solid #FA80541F;
  padding: 20px 44px 60px;
  align-items: center;
  gap: 10px;
  border-radius: 36px;
  width: fit-content;
  @media (max-width: 480px) {
    //padding: 0 10px;
    border-radius: 0;
    border: unset;
    gap: 9px;
  }
`

export const ImgBg1 = styled.img`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 70%;
  z-index: 0;
  transform: scale(1.2);
`

export const ImgBg2 = styled.img`
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 70%;
  z-index: 0;
  transform: scale(1.2);
`

export const TxtDes1 = styled.div`
  display: flex;
  flex-direction: row;
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 16px;
  line-height: 30px;
  text-decoration-skip-ink: none;
  text-align: center;
  z-index: 1;
  @media (max-width: 480px) {
    //margin-top: 0;
    font-size: 12px;
    line-height: 30px;
    text-decoration-skip-ink: none;
    //margin-bottom: -5px;
  }
`

export const TxtDes2 = styled.div`
  display: flex;
  flex-direction: row;
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 16px;
  line-height: 30px;
  text-decoration-skip-ink: none;
  text-align: center;
  z-index: 1;
  height: 80px;
  @media (max-width: 480px) {
    width: 303px;
    font-size: 12px;
    line-height: 25px;
    text-decoration-skip-ink: none;
  }
`

export const FrameDes1 = styled.div`
  word-wrap: break-word;
  white-space: -moz-pre-wrap;
  white-space: pre-wrap;
`

export const Border4 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  //flex: 1;
  border: 2px solid #F4486229;
  padding: 30px 20px 20px;
  gap: 10px;
  border-radius: 32px;
  width: fit-content;
  z-index: 1;
  @media (max-width: 480px) {
    //padding: 0 20px 20px;
    gap: 9px;
  }
`

export const Border5 = styled.div`
  display: flex;
  flex-direction: column;
  //flex: 1;
  border: 2px solid #E4265B3D;
  padding: 32px;
  gap: 10px;
  border-radius: 28px;
  width: fit-content;
  @media (max-width: 480px) {
    //padding: 30px;
    gap: 9px;
  }
`

export const Border6 = styled.div`
  display: flex;
  flex-direction: column;
  //flex: 1;
  //border: 1px solid #E4275B;
  border-radius: 21px;
  height: 350px;
  width: 231px;
  overflow: hidden;
  @media (max-width:480px){
    //height: 200px;
    //height: fit-content;
  }
`

export const FrameVideo = styled.video<{display : boolean}>`
  display: ${props => props.display ? 'flex' : 'none'};
  height: 350px;
  //width: 240px;
`

export const TxtA = styled.div`
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 31px;
  line-height: 41px;
  margin-top: 400px;
  width: 388px;
  text-align: center;
  z-index: 9999 !important;
  @media (max-width: 480px) {
    font-size: 24px;
    line-height: 41px;
  }
  @media (max-width:960px){
    font-size: 24px;
    line-height: 41px;
    width: fit-content;
  }
`

export const FrameNow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px;
  gap: 12px;
  border-radius: 100px;
  background: linear-gradient(72.23deg, #E01D59 0%, #FF5F67 100%);
  box-shadow: 0px 6px 24px 0px #F24361;
  margin-top: 20px;
  cursor: pointer;
  z-index: 999;
  &:hover {
    transform: scale(1.05);
  }
  @media (max-width: 480px) {
    //margin-top: 0;
    //margin-bottom: 20px;
  }
`

export const TxtNow = styled.div`
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.015em;
  text-decoration-skip-ink: none;
`

export const FrameSlide = styled.div`
  display: block;
  position: relative;
  width: 100%;
  height: 350px;
  z-index: 99;
  margin-top: -530px;
  .slick-slide {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .slick-arrow {
    display: none !important;
  }
   @media (max-width: 480px) {
      //margin-bottom: -570px;
   }
`

export const FrameOver = styled.div`
  display: flex;
  position: absolute;
  height: 352px;
  top: 0;
  right: 0;
  left: 0;
  background-image: linear-gradient(to right, black, transparent, transparent , black);
`

export const FrameImg = styled.img`
  height: 350px;
  width: 231px;
  border: 1px solid #E4275B;
  border-radius: 21px;
  object-fit: cover;
  margin-top: 1px;
`

export const FrameItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
`